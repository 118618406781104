import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import moment from 'moment'
import Vue3TouchEvents from "vue3-touch-events"

// Create App
let app = createApp(App)

// Lottie
app.use(Vue3Lottie)

// Touch Events
app.use(Vue3TouchEvents)

// Moment.js
app.config.globalProperties.$moment = moment

// Mount App
app.use(router).mount('#app')
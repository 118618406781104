import { createRouter, createWebHistory } from 'vue-router'

import Intro from '../views/Intro/Overview.vue'
import Overview from '../views/Dashboard/Overview.vue'
import Settings from '../views/Settings/Overview.vue'

const routes = [
  {
    path: '/:step?',
    name: 'Intro',
    component: Intro,
    meta: {
      Auth: false,
      Title: 'Intro'
    }
  },

  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
    meta: {
      Auth: true,
      Title: 'Overview'
    }
  },

  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      Auth: false,
      Title: 'Settings'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.Auth) {
    if(localStorage.getItem('access_token')) {
      next()
    } else {
      router.push({ path: '/intro' })
    }
  } else {
    next()
  }

  document.title = to.meta.title + ' | Babyday.app'
})

export default router
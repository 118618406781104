<template>
    <div class="onboarding">
        <div class="lang" v-if="Step == 'language'">
            <span class="title">Please select a language</span>
            <div class="item" v-for="(Item, i) in Languages" :key="i" @click="SetLanguage()">
                <img :src="require('@/assets/flags/' + Item.Code + '.svg')">
                <span class="name">{{ Item.Name }}</span>
            </div>
        </div>
        <div class="steps">
            <div class="step" v-if="Step == 'care'">
                <div class="image">
                    <div class="img"></div>
                </div>
                <div class="about">
                    <span class="title">Care Lorem Ipsum</span>
                    <span class="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>
            </div>
            <div class="step" v-if="Step == 'growth'">
                <div class="image">
                    <div class="img"></div>
                </div>
                <div class="about">
                    <span class="title">Growth Lorem Ipsum</span>
                    <span class="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>
            </div>
            <div class="step" v-if="Step == 'team'">
                <div class="image">
                    <div class="img"></div>
                </div>
                <div class="about">
                    <span class="title">Teamwork Lorem Ipsum</span>
                    <span class="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>
            </div>
            <div class="step" v-if="Step == 'notifications'">
                <div class="image">
                    <div class="img"></div>
                </div>
                <div class="about">
                    <span class="title">Notifications Lorem Ipsum</span>
                    <span class="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>
            </div>
            <div class="account" v-if="Step == 'login'">
                <div class="form">
                    <span class="title">Welkom</span>
                    <span class="subtitle">Voor het gebruik van onze app heb je een account nodig. Je kan hier inloggen of kies ervoor om een account aan te maken. Wij gebruiken deze gegevens alleen voor het gebruik van deze app.</span>
                    <span class="field">E-mailadres</span>
                    <input type="email" v-model="Email">
                    <span class="field">Wachtwoord</span>
                    <input type="password" v-model="Password">
                    <button class="button login" @click="Login()">Inloggen</button>
                    <button class="button register" @click="Step == 'Register'">Account aanmaken</button>
                </div>
            </div>
            <div class="menu" v-if="Step !== 'language' && Step !== 'login'">
                <div class="previous" @click="Steps('previous')">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="next"> <g> <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 "></polygon> </g> </g> </g></svg>
                </div>
                <div class="dots">
                    <svg class="dot" :class="{ filled : Step == 'care' }" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g><path fill="transparant" d="M8 3a5 5 0 100 10A5 5 0 008 3z"></path></g></svg>
                    <svg class="dot" :class="{ filled : Step == 'growth' }" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g><path fill="transparant" d="M8 3a5 5 0 100 10A5 5 0 008 3z"></path></g></svg>
                    <svg class="dot" :class="{ filled : Step == 'team' }" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g><path fill="transparant" d="M8 3a5 5 0 100 10A5 5 0 008 3z"></path></g></svg>
                    <svg class="dot" :class="{ filled : Step == 'notifications' }" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g><path fill="transparant" d="M8 3a5 5 0 100 10A5 5 0 008 3z"></path></g></svg>
                </div>
                <div class="next" @click="Steps('next')">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="next"> <g> <polygon points="6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 "></polygon> </g> </g> </g></svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// HTTP
import { CapacitorHttp } from '@capacitor/core';

export default {
    name: 'IntroOverview',

    data () {
        return {
            Step: 'login',
            Languages: [
                { Name: 'Deutsch', Code: 'de' },
                { Name: 'English (USA)', Code: 'us'  },
                { Name: 'English (UK)', Code: 'gb' },
                { Name: 'Español', Code: 'es' },
                { Name: 'Français', Code: 'fr' },
                { Name: 'Italiano', Code: 'it' },
                { Name: 'Nederlands', Code: 'nl' },
                { Name: 'Português', Code: 'pt' }
            ],
            Email: 'father@wijn.com',
            Password: '12345678'
        }
    },

    mounted () {
        if(this.$route.params.step) {
            this.Step = this.$route.params.step
        }

        if(this.$root.getAccessToken()) {
            this.$router.push('/overview')
        }
    },

    methods: {
        SetLanguage () {
            this.Step = 'care'
        },

        Steps (direction) {
            if(this.Step == 'care') {
                if(direction == 'previous') {
                    this.Step = 'language'
                } else {
                    this.Step = 'growth'
                }
            } else if(this.Step == 'growth') {
                if(direction == 'previous') {
                    this.Step = 'care'
                } else {
                    this.Step = 'team'
                }
            } else if(this.Step == 'team') {
                if(direction == 'previous') {
                    this.Step = 'growth'
                } else {
                    this.Step = 'notifications'
                }
            } else if(this.Step == 'notifications') {
                if(direction == 'previous') {
                    this.Step = 'team'
                } else {
                    this.Step = 'login'
                }
            }
        },

        async Login () {
            await CapacitorHttp.post({
                url: 'https://babyday.app/api/authenticate/login',
                data: {
                    Email: this.Email,
                    Password: this.Password
                },
                headers: {
                    'Content-Type': 'application/json'
                },
                webFetchExtra: {
                    credentials: "include"
                }
            }).then(response => {
                this.$root.setAccessToken(response.data.access_token)
                if(response.data.error == 0) {
                    this.$router.push('/overview')
                }
            });
        },
    }
}
</script>

<style scoped>
.onboarding {
    width: 100%;
    height: 100%;
    background: #fff;
}

.lang {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    padding: 25px;
}

.lang .title {
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    padding: 50px 0;
}

.lang .item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(50% - 60px);
    padding: 25px;
    border: solid 1px #e1e1e1;
    border-radius: 15px;
    background: #fafafa;
}

.lang .item img {
    width: 35px;
    margin: 0 0 15px 0;
}

.lang .item .name {
    font-weight: 300;
}




.steps {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.steps .step {
    height: calc(100% - 75px);
}

.steps .step .image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
}

.steps .step .image .img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: indianred;
}

.steps .step .about {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 40%;
}

.steps .step .about .title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 15px 0;
}

.steps .step .about .subtitle {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}





.account {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.account .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px;
}

.account .form .title {
    margin: 0 0 5px 0;
    font-size: 30px;
    font-weight: 500;
}

.account .form .subtitle {
    margin: 0 0 35px 0;
    font-size: 16px;
    font-weight: 300;
}

.account .form .field {
    margin: 0 0 5px 0;
    font-size: 18px;
    font-weight: 300;
}

.account .form input {
    padding: 15px;
    margin: 0 0 35px 0;
    border: solid 1px #000;
    border-radius: 0;
    font-size: 16px;
    font-weight: 300;
    outline: none;
}

.account .button {
    padding: 15px;
    font-size: 18px;
    font-weight: 300;
    background: indianred;
    color: #fff;
    border: solid 1px #000;
    margin: 0 0 15px 0;
}

.account .button.register {
    background: transparent;
    color: #000;
}





.menu {
    display: flex;
    height: 75px;
}

.menu .previous {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
}

.menu .previous svg {
    width: 25px;
    height: 25px;
    padding: 5px;
    transform: rotate(180deg);
}

.menu .dots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 150px);
}

.menu .dots .dot {
    width: 20px;
    height: 20px;
    stroke: #000;
    fill: transparent;
}

.menu .dots .dot.filled {
    fill: #000;
}

.menu .next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
}

.menu .next svg {
    width: 25px;
    height: 25px;
    padding: 5px;
}
</style>
<template>
  <div class="intro active" v-if="Intro">
    <Vue3Lottie :animationData="require('@/assets/animations/intro.json')" :width="250" :loop="false" :speed="1.25" @onComplete="closeIntro" />
  </div>
  <router-view class="router" />
  <Menu class="menu" v-if="$route.fullPath !== '/'" />
</template>

<script>
import Menu from '@/views/Layout/Menu.vue';

export default {
  name: 'App',

  components: {
    Menu
  },

  data () {
    return {
      Intro: true
    }
  },

  methods: {
    closeIntro () {
      document.querySelector('.intro').classList.remove('active')
      var self = this
      setTimeout(function() {
          self.Intro = false
      }, 500)
    },
    
    setAccessToken (token) {
      if(token) {
        localStorage.setItem('access_token', token)
      }
    },

    getAccessToken () {
      return localStorage.getItem('access_token')
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

* {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body ,#app {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

#app {
  background: #ffecec;
}

.router {
  height: calc(100% - 101px);
}

.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ee6969;
  z-index: 9999;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}

.intro.active {
  opacity: 1;
}
</style>